.week2__background {
  background-color: #e4e4e4;
  padding: 0;
  margin: 0;
}

.week2__container,
h1 {
  margin: 20px;
  width: 97%;
  text-align: center;
}

.week2__transitions {
  margin: 20px;
  padding: 16px;
  border: 1px solid gray;
  border-radius: 15px;
  background-color: #f5f5f0;
  text-align: left;
  display: flex;
  justify-content: space-evenly;
}

.week2__separator {
  height: 15px;
}

.week2__animations {
  margin: 20px;
  padding: 16px;
  height: 300px;
  border: 1px solid gray;
  border-radius: 15px;
  background-color: #f5f5f0;
}

/* Transistions */

#btn1 {
  width: 55px;
  height: 55px;
  display: inline-block;
  background-color: cornflowerblue;
  transition: all 0.1s ease-in-out;
  transform: translate3D(0, 0, 0);
}

#btn1:hover {
  cursor: pointer;
  background-color: #00ff00;
}

#btn2 {
  width: 55px;
  height: 55px;
  display: inline-block;
  background-color: cornflowerblue;
  transition: all 0.1s ease-in-out;
  transform: translate3D(0, 0, 0);
}

#btn2:hover {
  cursor: pointer;
  filter: drop-shadow(8px 8px 5px gray);
  background-color: indigo;
}

#btn3 {
  width: 55px;
  height: 55px;
  display: inline-block;
  background-color: cornflowerblue;
  transition: width 2s, height 2s;
  transform: translate3D(0, 0, 0);
}

#btn3:hover {
  cursor: pointer;
  width: 150px;
  height: 150px;
  transform: rotate(180deg);
  background-color: hotpink;
}

#btn4 {
  width: 55px;
  height: 55px;
  display: inline-block;
  background-color: cornflowerblue;
  transition-property: width;
  transition-duration: 0.5s;
  transition-timing-function: linear;
}

#btn4:hover {
  cursor: pointer;
  width: 100px;
  background-color: yellow;
}

/* Animations */

#ball {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ff5722;
  display: inline-block;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
      transform: translate3d(0, 0, 0);
  }
  to {
      transform: translate3d(0, 275px, 0);               
  }
}
