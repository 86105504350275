.week3_wrapper {
  margin: 40px;
}

#myForm {
  display: none;
  position: fixed;
  z-index: 1; /* Sit on top */
  top: 75px;
  left: 150px;
  width: 70%;
  overflow: auto; /* Enable scroll if needed */
  animation: animatetop 0.2s ease-out;
}

#close,
#main {
  display: inline-block;
}
.week3__h2{
  display: inline-block;
}

.week3__img {
  width: 350px;
  margin-right: 60px;
  padding: 15px;
  float: left;
}

@keyframes animatetop {
    from {
        top: -100px;
        opacity: 0;
    }
    to {
        top: 75px;
        opacity: 1
    }
}