.week6__cbg {
    position: fixed;
    z-index: 1;
    border: 1px solid gray;
    border-radius: 15px;
    background-color: #ffccd9;
}

.week6__canvas {
    position: fixed;
    z-index: 2;
    border: 1px solid gray;
    border-radius: 15px;
}