.project {
  margin-bottom: 135px;
}

.project__title{
    font-size: 40px;
    text-align: center;
}
.project__img {
  width: 100%;
  transition: all 600ms ease;
}

.project__wrapper {
  display: flex;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.45);
  overflow: hidden;
}

.project__wrapper:hover .project__description {
  opacity: 1;
  transform: translateY(-50%);
}

.project__wrapper:hover .project__img {
  transform: scale(1.07);
  filter: blur(5px);
}

.project__description {
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 300ms, transform 450ms ease;
  color: white;
  max-width: 550px;
}

.project__wrapper:hover .project__wrapper--bg {
  opacity: 0.7;
  visibility: visible;
}

.project__wrapper--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1d25;
  opacity: 0;
  transition: all 450ms ease;
  visibility: hidden;
}

.project__description--title {
  font-size: 40px;
}

.project__description--sub-title {
  margin-top: 8px;
}

.project__description--link {
  color: white;
  font-size: 28px;
  margin-right: 16px;
}

.project__description--para {
  margin: 16px 0;
}

#projects {
  background-color: rgb(236, 236, 236);
}

.projects__section--wrapper {
  max-width: 1000px;
  margin: 0 auto;
}

.project__description--title {
  text-align: left;
}

.projects__section--title {
  margin-bottom: 50px;
}

@media (max-width: 568px) {
  .project__description--para {
    display: none;
  }

  .project__description {
    left: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .project__description--sub-title {
    margin-bottom: 8px;
  }

  .project__img {
    height: 225px;
  }

  .project__description--title {
    text-align: center;
    padding: 10px;
  }
}
