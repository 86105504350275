@import url(../src/WeeklyLabs/Week1/Week1.css);
@import url(../src/WeeklyLabs/Week2/Week2.css);
@import url(../src/WeeklyLabs/Week3/Week3.css);
@import url(../src/WeeklyLabs/Week4/Week4.css);
@import url(../src/WeeklyLabs/Week5/Week5.css);
@import url(../src/WeeklyLabs/Week6/Week6.css);
@import url(../src/WeeklyLabs/ProjectDisplay.css);

ul {
  list-style: none;
}

.project__footer {
  
  text-align: center;}

#summary {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 94vh;
  padding: 0px 12px;
}

.red-color {
  color: red;
}

h1 {
  font-size: 84px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 26px;
  padding: 50px 0 15px;
  text-align: center;
}

.summary-position {
  margin-top: -50px;
}

.summary-text {
  font-size: 24px;
  font-weight: 500;
  max-width: 500px;
  margin: 20px auto 0;
}

.svg-wrapper {
  display: flex;
  justify-content: center;
}

.svg-wrapper svg {
  fill: red;
  width: 30px;
  height: 30px;
  padding: 10px 5px;
  transition: 0.3s;
}

.svg-wrapper svg:hover {
  opacity: 0.7;
  transition: 0.3s;
}

/* mouse scroll */
.scroll-downs {
  width: 34px;
  height: 55px;
  position: absolute;
  bottom: 10%;
}
.mousey {
  width: 3px;
  padding: 7px 11px;
  height: 35px;
  border: 3px solid red;
  border-radius: 25px;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: red;
  animation-name: scroll;
  animation-duration: 1.6s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}

.webgl {
  position: absolute;
  top: 0;
  z-index: -1;
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
