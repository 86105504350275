.week1__background {
  padding: 0;
  margin: 0;
  background-color: #e4e4e4;
}

.week1__container,
h1 {
  margin: 20px;
  width: 97%;
  text-align: center;
}

.week1__animations {
  margin: 20px;
  padding: 16px;
  border: 1px solid gray;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.week1__separator {
  height: 15px;
}

.week1__transistions {
  margin: 20px;
  padding: 16px;
  border: 1px solid gray;
  border-radius: 15px;
  text-align: center;
}

.week1__img {
  width: 150px;
}

/* Animations */

#a1 {
  animation: move1 5s 3;
}
@keyframes move1 {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}

#a2 {
  animation: move2 5s 5;
}
@keyframes move2 {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  50% {
    transform: translateX(500px) rotate(360deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

#a3 {
  animation: move3 10s 3;
}
@keyframes move3 {
  0% {
    transform: translateX(0px) rotate(0deg);
  }
  25% {
    transform: translateX(500px) rotate(360deg);
  }
  50% {
    transform: translateX(0px) rotate(0deg);
  }
  75% {
    transform: translateX(-500px) rotate(-360deg);
  }
  100% {
    transform: translateX(0px) rotate(0deg);
  }
}

/* Transistions */

#t1 {
  transition: transform 1s;
}
#t1:hover {
  cursor: pointer;
  transform: rotateX(145deg);
}

#t2 {
  transition: transform 1s;
}
#t2:hover {
  cursor: pointer;
  transform: rotateZ(225deg) scale(0.8);
}

#t3 {
  transition: transform 1s;
}
#t3:hover {
  cursor: pointer;
  transform: rotateY(145deg) scale(1.1);
}
