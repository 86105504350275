.week4__body {
  margin-left: 15px;
  font-family: sans-serif;
  font-size: 1.3em;
}
.logo {
  width: 100%;
  position: relative;
}
.week4__ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li {
  float: none;
}
li a {
  font-size: 1 em;
  display: block;
  padding: 8px;
  font-family: sans-serif;
  color: white;
}
.nav {
  display: none; /* normally hidden */
  width: 25%; /* set width to 25% of screen width - responsive */
  left: 0; /* start at absolute left */
  position: fixed; /* want the menu to remain fixed, even during scrolling */
  z-index: 3; /* ensure it is on top */
  border: 1px solid gray;
  border-radius: 10px; /* rounded corners for border */
  background-color: darkgreen;
  height: 100%; /* ensure it covers entire left side to bottom */
}
li#close {
  padding: 8px;
  color: white;
}

li#close:hover {
  cursor: pointer;
  color: red;
}

/*  body links*/
p a {
  font-family: sans-serif;
  font-size: 1em;
  color: darkgreen;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: darkgreen;
  transition: all 0.2s ease-out;
  padding: 3px;
  background: linear-gradient(
    to bottom,
    rgba(255, 194, 102, 0) 0%,
    rgba(255, 194, 102, 0) 50%,
    rgba(255, 194, 102, 1) 50%,
    rgba(255, 194, 102, 1) 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 200%;
}

p a:hover {
  color: black;
  background-position: 0 100%;
}
.myBtn {
  display: none; /*  comment this out at first */
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.myBtn:hover {
  background-color: #555;
}
.menu {
  font-size: 2em;
  position: fixed; /* needed to stay in one place */
  z-index: 3; /* needed to keep this in front of scrolling content */
  padding: 5px;
  transition: all 0.1s ease-out; /* control the animation of the hover effect */
}
.menu:hover {
  color: deeppink; /* change text color on hover */
  cursor: pointer; /* Chnage cursoe to pointer on hover */
  transform: scale(1.5); /* scale icon 1.5 times on hover */
}
#main {
  margin-left: 0;
  transition: all 0.3s ease-out;
}
.nav {
  animation: animateleft 0.4s;
}
html {
  scroll-behavior: smooth;
}

.openMenu #nav{
    display:block;
}
.openMenu #myMenu{
    display: none;
}
.openMenu #main{
    margin-left: 25%;
}

.week4__link{
  font-size: 40px;
  text-align: center;
  padding: 20px;
}

@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
